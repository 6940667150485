import { useState } from 'react'
import { Form, Icon, Segment } from 'semantic-ui-react'

export function Login({ onSuccess }) {

    const [pw, setPw] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const submit = (e) => {
        if (e.preventDefault) {
            e.preventDefault()
        }
        setLoading(true)
        fetch('/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Secret": pw
            },
        }).then(resp => {
            setLoading(false)
            if (!resp.ok) {
                setError(true)
            } else {
                onSuccess(pw)
            }
            
            console.log(resp.ok)
        }).catch(ex => {
            console.error(ex);
            setLoading(false);
            setError(true);
        })
        
    }

    return (
        <div style={{ backgroundColor: '#1D3D50', height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src="/logo.png" width="200" />
            <Segment inverted style={{ backgroundColor: 'rgba(0,0,0,.3)' }}>
                        <p>Diese Freigabe ist passwortgesch&uuml;tzt</p>
                <Form onSubmit={submit} >
                    <Form.Input error={error} loading={loading} type="password" value={pw} onChange={(_, data) => {
                        if (!loading) {
                        setPw(data.value)
                        }
                    }} 
                    icon={loading ? undefined : <Icon name="arrow right" link onClick={submit} />} placeholder="Passwort" />
                </Form>
            </Segment>
            </div>
        </div>
    )
}