import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Container,
  Dimmer,
  Icon,
  Label,
  Loader,
  Menu,
  Progress,
  Table,
} from "semantic-ui-react";
import { Login } from "./Login";

export default function App() {
  const [files, setFiles] = useState(null);
  const [breads, setBreads] = useState([]);
  const { search } = useLocation();
  const inputRef = useRef(null)
  const [progress, setProgress] = useState(-1)

  const [password, setPassword ] = useState(localStorage.getItem('password'))

  useEffect(() => {
    setFiles(null);
    const query = async () => {
      const path = search.replace("?path=", "");
      const resp = await fetch("/files?path=" + path, {
        headers: { 'Secret': password }
      });
      if (resp.ok) {
        setFiles(await resp.json());
      }
    };
    if (password) {
    query();
    setBreads(
      decodeURIComponent(search.replace("?path=", "")).split("/").slice(1)
    );
    }
  }, [search, password]);
  
  const currentFolder = breads[breads.length - 1];
  const allowUpload = currentFolder === "Text" || currentFolder === "Bilder";
  
  if (!password) {
    return <Login onSuccess={(pw) => {
      localStorage.setItem('password', pw)
      setPassword(pw)
    }} />
  }

  return (
    <>
      <Menu inverted style={{ borderRadius: "none", backgroundColor: '#1D3D50' }} borderless>
        <Menu.Item>
          <img src="/logo.png" />
        </Menu.Item>
        <Menu.Item header as={Link} to="/">
          Das Buch
        </Menu.Item>
        {breads.map((b, i) => {          
          return (
            <Menu.Item
              as={Link}
              to={`?path=${encodeURIComponent(
                "/" + breads.slice(0, i + 1).join("/")
              )}`}
            >
              <Icon name="chevron right" />
              {b}
            </Menu.Item>
          );
        })}
      </Menu>
      {files === null && (
          <Loader active />
      )}
      {allowUpload && (
        
        <div style={{ paddingLeft: "1.14285714rem"}}>
          <input ref={inputRef} type="file" multiple name="file" style={{position: 'absolute',
            zIndex: -1,
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            opacity: 0,}} />
          
          <Button as="div" labelPosition="right" disabled={progress !== -1} onClick={() => {
            if (inputRef.current) {
              if (currentFolder === 'Bilder') {
                inputRef.current.accept = ".jpg,.png,.tiff"
              } else if (currentFolder === 'Text') {
                inputRef.current.accept = '.pdf,.docx,.txt,.odt,.doc,.rtf'
              }
              inputRef.current.click()
              inputRef.current.onchange = async (e) => {
                e.preventDefault();
                if (e.target.files && e.target.files.length > 0) {
                  const ajax = new XMLHttpRequest()
                  ajax.open("POST", "/files" + search)
                  ajax.setRequestHeader('Secret', password)
                  const formData = new FormData()
                  

                  for (const file of e.target.files) {

                    formData.append('files', file)
                  }

                  ajax.upload.onprogress = (ev) => {
                    
                    setProgress((ev.loaded * 100 / ev.total).toFixed(2))
                  }
                  ajax.upload.onloadend = () => {
                    const query = async () => {
                      const path = search.replace("?path=", "");
                      
                      const resp = await fetch("/files?path=" + path, { headers: {'Secret': password}});
                      if (resp.ok) {
                        setFiles(await resp.json());
                        
                      }
                      setProgress(-1)
                    };
                    query()
                  }
                  ajax.send(formData)
                }
              };
            }
          }}>
            <Button basic color="grey">
              { progress === -1 ? <Icon name="plus" /> :  <Loader size="mini" active inline />}
            </Button>
            <Label as="a" basic color="grey" pointing="left">
              Datei hochladen { progress > -1 && `(${progress}%)` }
            </Label>
          </Button>
        </div>
      )}
      {files && (
        <Table selectable basic="very">
          <Table.Body>
            {files.map((f) => {
              let icon = 'folder'
              if (currentFolder === 'Bilder') {
                icon = 'image'
              } else if (currentFolder === 'Text') {
                icon = 'file text'
              } 

              return (
                <Table.Row key={f.name}>
                  <Table.Cell collapsing style={{ paddingLeft: "1.14285714rem" }}>
                    <Icon name={icon} />
                  </Table.Cell>
                  <Table.Cell selectable={icon === 'folder'}>
                    { icon === 'folder' ? <Link to={`?path=${encodeURIComponent(f.path)}`}>
                      
                    {f.name}
                    </Link> : f.name }
                    { !!f.type && f.name }
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
}
